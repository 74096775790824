import axios from 'config/axios';
import { IEditProfileFormValues } from './user.utils';

export const updateCurrentUser = async (data: IEditProfileFormValues) => {
  const {
    data: {
      data: { user },
    },
  } = await axios.patch('/users/me', data);
  return user as User;
};

export const getCurrentUser = async () => {
  const {
    data: {
      data: { user },
    },
  } = await axios.get('/users/me');
  return user as User;
};

export const getGoogleOAuthScreen = async (sourceUri: string) => {
  const {
    data: {
      data: { oauthUrl },
    },
  } = await axios.get(`/auth/login?sourceUri=${encodeURIComponent(sourceUri)}`);
  return oauthUrl as string;
};

export const logout = () => axios.get('/auth/logout');
