import { useQuery } from '@tanstack/react-query';

import queryKeys from 'utils/query-keys';
import { getCurrentUser } from '../user.api';

const useCurrentUser = () => {
  const {
    data: user,
    isLoading,
    error,
  } = useQuery({
    queryKey: [queryKeys.getCurrentUser],
    queryFn: getCurrentUser,
    retry: false,
    staleTime: Infinity,
  });

  return { user, isLoading, error };
};

export default useCurrentUser;
