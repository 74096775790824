import React, { FC } from 'react';

import { cn } from 'utils/shadcn-utils';

interface TypographyProps extends React.HTMLProps<HTMLHeadingElement> {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'subtitle';
}

export const Typography: FC<TypographyProps> = ({
  variant = 'secondary',
  className,
  children,
  ...rest
}) => {
  switch (variant) {
    case 'primary':
      return (
        <h1
          style={{ WebkitBackgroundClip: 'text' }}
          className={cn(
            'bg-gradient-to-r from-secondary via-primary-light to-primary pb-3 text-6xl font-bold capitalize text-transparent',
            className,
          )}
          {...rest}
        >
          {children}
        </h1>
      );

    case 'secondary':
      return (
        <h2
          className={cn(
            'text-4xl font-semibold capitalize text-primary-darker first:mt-0 dark:text-gray-100',
            className,
          )}
          {...rest}
        >
          {children}
        </h2>
      );

    case 'tertiary':
      return (
        <h3
          className={cn('text-xl font-semibold text-primary-darker dark:text-gray-100', className)}
          {...rest}
        >
          {children}
        </h3>
      );

    case 'subtitle':
      return (
        <h6
          className={cn('font-medium text-primary-darker dark:text-gray-100', className)}
          {...rest}
        >
          {children}
        </h6>
      );

    default:
      return null;
  }
};
