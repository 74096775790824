import React, { FC } from 'react';
import { Link } from 'gatsby';

import { navigationLinks } from 'utils/data';
import { Button } from 'shadcn/button';
import logoImg from 'images/logo.webp';
import { useCurrentUser } from 'features/user/hooks';
import { Avatar, AvatarFallback, AvatarImage } from 'shadcn/avatar';

const Navigation: FC = () => {
  const { user } = useCurrentUser();

  return (
    <nav className='sticky top-0 z-50 border-b bg-primary-darkest bg-opacity-75 py-3 saturate-150 backdrop-blur-md backdrop-filter'>
      <div className='container flex items-center justify-between capitalize'>
        <h4>
          <img src={logoImg} alt='LOGO' className='h-12' />
        </h4>

        <ul className='flex gap-16'>
          {navigationLinks.map((navLink, idx) => (
            <li key={idx}>
              <Link
                activeClassName='before:scale-x-100'
                className='relative will-change-transform before:absolute before:-bottom-2 before:h-[2px] before:w-full before:scale-x-0 before:rounded-full before:bg-primary before:transition-transform before:content-[""] hover:before:scale-x-100'
                to={navLink.href}
                partiallyActive={navLink.partiallyActive}
              >
                {navLink.text}
              </Link>
            </li>
          ))}
        </ul>

        {user ? (
          <Link to='/account' className='flex items-center gap-2'>
            <Avatar>
              <AvatarImage src={user.picture} alt={user.name} />
              <AvatarFallback>{user.name[0]}</AvatarFallback>
            </Avatar>
            <span>{user.name.split(' ')[0]}</span>
          </Link>
        ) : (
          <Link to='/login'>
            <Button
              variant='outline'
              className='capitalize text-primary-lightest'
            >
              sign in
            </Button>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
