import React, { FC } from 'react';
import { Link } from 'gatsby';

import logoImg from 'images/logo.webp';
import { contacts, navigationLinks, socials } from 'utils/data';
import { Typography } from 'shadcn/typography';
import { Icon } from 'shadcn/icon';
import { Separator } from 'shadcn/separator';

const Footer: FC = () => (
  <footer className='container mb-10 rounded-lg border py-10'>
    <div className='grid grid-cols-4 justify-items-center gap-10 lg:grid-cols-none lg:justify-items-start'>
      <Link to='/' className='self-center'>
        <img src={logoImg} alt='LOGO' className='w-60 lg:mx-auto lg:mt-10' />
      </Link>

      <div>
        <Typography className='mb-6 text-white'>quick links</Typography>
        <ul className='flex flex-col gap-x-8 gap-y-4 capitalize lg:flex-row sm:flex-col'>
          {[...navigationLinks.slice(1), { text: 'Privacy policy', href: '/privacy-policy' }].map(
            (navLink, idx) => (
              <li key={idx}>
                <Link to={navLink.href}>{navLink.text}</Link>
              </li>
            ),
          )}
        </ul>
      </div>

      <div>
        <Typography className='mb-6 text-white'>contact</Typography>
        <ul className='flex flex-col gap-x-8 gap-y-4 lg:flex-row sm:flex-col'>
          {contacts.map((contact, idx) => (
            <li key={idx}>
              <a href={contact.link} target='_blank' rel='noreferrer' className='flex gap-2'>
                <Icon name={contact.icon as any} className='shrink-0' />
                <span>{contact.text}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <Typography className='mb-6 text-white'>Social</Typography>
        <ul className='flex flex-col gap-x-8 gap-y-4 lg:flex-row sm:flex-col'>
          {socials.map((social, idx) => (
            <li key={idx}>
              <a href={social.link} target='_blank' rel='noreferrer' className='flex gap-2'>
                <Icon name={social.icon as any} className='shrink-0' />
                <span>{social.text}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>

    <Separator className='my-8 bg-gray-700' />

    <div className='flex justify-between gap-4 md:flex-col'>
      <span>&copy; {new Date().getFullYear()} Copyright. All rights reserved.</span>
      <span>Developed by FIBO 1618.</span>
    </div>
  </footer>
);

export default Footer;
