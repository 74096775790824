const queryKeys = {
  // Booking
  getBookings: 'get-bookings',

  // User
  getCurrentUser: 'get-current-user',

  // Invoice
};

export default queryKeys;
