import React, { FC, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Toaster } from 'shadcn/sonner';
import { Icon } from 'shadcn/icon';
import Layout from './layout';

// import Layout from 'components/layout';

interface AppProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

const App: FC<AppProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <Layout>{children}</Layout>
    <Toaster
      expand
      closeButton
      position='bottom-center'
      icons={{
        success: <Icon name='CheckCircle' className='text-green-400' />,
        error: <Icon name='AlertOctagon' className='text-red-400' />,
      }}
    />
    <ReactQueryDevtools />
  </QueryClientProvider>
);

export default App;
